:root {
  --global-base-white: #fff;
  --global-neutral-grey-1000: #4f4d55;
  --global-neutral-grey-1100: #2d2b32;
  --global-neutral-grey-1300: #0a090b;
  --global-neutral-grey-300: #f1f1f1;
  --global-neutral-grey-400: #ececed;
  --global-neutral-grey-500: #e6e6e6;
  --global-neutral-grey-600: #dcdcde;
  --global-neutral-grey-700: #c9c9cc;
  --global-neutral-grey-900: #7f7d83;
  --global-red-100: #feebeb;
  --global-red-200: #ffe3e3;
  --global-red-700: #f53535;
  --global-red-800: #e12121;
  --globalbasewhite: #fff;
  --globalblue-1000: #113b98;
  --globalblue-900: #1751d0;
  --globalneutral-grey200: #f5f5f5;
  --kokorkoactive-indicator: #f3f8ff;
  --kokorkoblackblack-100: #b0b0b0;
  --kokorkoblackblack-200: #8a8a8a;
  --kokorkoblackblack-300: #545454;
  --kokorkoblackblack-400: #222;
  --kokorkoblackblack-50: #e6e6e6;
  --kokorkoblackblack-500: #000;
  --kokorkoblackblack-600: #000;
  --kokorkoblackblack-700: #000;
  --kokorkoblackblack-800: #000;
  --kokorkoblackblack-900: #222;
  --kokorkoblueblue-100: #bcc9e3;
  --kokorkoblueblue-200: #9cafd6;
  --kokorkoblueblue-300: #6e8bc3;
  --kokorkoblueblue-400: #5275b7;
  --kokorkoblueblue-50: #e9eef6;
  --kokorkoblueblue-600: #234b96;
  --kokorkoblueblue-700: #1c3a75;
  --kokorkoblueblue-800: #152d5b;
  --kokorkoblueblue-900: #102245;
  --kokorkoblueprimary-500: #2752a5;
  --kokorkogreygrey-100: #e9e9e9;
  --kokorkogreygrey-200: #dedede;
  --kokorkogreygrey-300: #cfcfcf;
  --kokorkogreygrey-400: #c6c6c6;
  --kokorkogreygrey-50: #f8f8f8;
  --kokorkogreygrey-500: #b8b8b8;
  --kokorkogreygrey-600: #a7a7a7;
  --kokorkogreygrey-700: #838383;
  --kokorkogreygrey-800: #656565;
  --kokorkogreygrey-900: #4d4d4d;
  --kokorkoredred-100: #ffc0b2;
  --kokorkoredred-200: #ffa28c;
  --kokorkoredred-300: #ff7758;
  --kokorkoredred-400: #ff5d37;
  --kokorkoredred-50: #ffebe6;
  --kokorkoredred-600: #e82f05;
  --kokorkoredred-700: #b52504;
  --kokorkoredred-800: #8c1d03;
  --kokorkoredred-900: #6b1602;
  --kokorkoredsecondary-500: #ff3405;
  --kokorkowhite: #fafafa;
  --medium-12-font-family: "Inter", Helvetica;
  --medium-12-font-size: 12px;
  --medium-12-font-style: normal;
  --medium-12-font-weight: 400;
  --medium-12-letter-spacing: 0px;
  --medium-12-line-height: 18px;
  --medium-14-font-family: "Inter", Helvetica;
  --medium-14-font-size: 14px;
  --medium-14-font-style: normal;
  --medium-14-font-weight: 400;
  --medium-14-letter-spacing: -.05px;
  --medium-14-line-height: 20px;
  --medium-16-font-family: "Inter", Helvetica;
  --medium-16-font-size: 16px;
  --medium-16-font-style: normal;
  --medium-16-font-weight: 400;
  --medium-16-letter-spacing: -.18px;
  --medium-16-line-height: 22px;
  --medium-18-font-family: "Inter", Helvetica;
  --medium-18-font-size: 18px;
  --medium-18-font-style: normal;
  --medium-18-font-weight: 400;
  --medium-18-letter-spacing: -.26px;
  --medium-18-line-height: 24px;
  --primary500-base: #2080f6;
  --regular-14-font-family: "Inter", Helvetica;
  --regular-14-font-size: 14px;
  --regular-14-font-style: normal;
  --regular-14-font-weight: 400;
  --regular-14-letter-spacing: -.05px;
  --regular-14-line-height: 20px;
  --semibold-14-font-family: "Inter", Helvetica;
  --semibold-14-font-size: 14px;
  --semibold-14-font-style: normal;
  --semibold-14-font-weight: 600;
  --semibold-14-letter-spacing: -.02px;
  --semibold-14-line-height: 20px;
  --semibold-16-font-family: "Inter", Helvetica;
  --semibold-16-font-size: 16px;
  --semibold-16-font-style: normal;
  --semibold-16-font-weight: 600;
  --semibold-16-letter-spacing: -.18px;
  --semibold-16-line-height: 22px;
  --shadow-hard-2x-small: 0px 0px 0px 1px #0a090b0d, 0px 2px 7px 0px #0a090b0d, 0px 2px 5px -2px #0a090b0f;
  --shadow-hard-extra-small: 0px 0px 0px 1px #0a090b0d, 0px 2px 2px -1px #0a090b0a, 0px 2px 12px -1px #0a090b1a;
  --shadow-hard-small: 0px 0px 0px 1px #0a090b0d, 0px 6px 16px 0px #0a090b14;
  --shadow-soft-2x-small: 0px 1.5px 4px -1px #0a090b12;
  --states-focused-accent: 0px 0px 0px 3px #618df2, 0px 0px 0px 1px #fff;
  --states-focused-destructive: 0px 0px 0px 3px #faa4a4, 0px 0px 0px 1px #fff;
  --states-focused-primary: 0px 0px 0px 3px #dcdcde, 0px 0px 0px 1px #fff;
}
/*# sourceMappingURL=index.333eaddb.css.map */
