:root {
  --global-base-white: rgba(255, 255, 255, 1);
  --global-neutral-grey-1000: rgba(79, 77, 85, 1);
  --global-neutral-grey-1100: rgba(45, 43, 50, 1);
  --global-neutral-grey-1300: rgba(10, 9, 11, 1);
  --global-neutral-grey-300: rgba(241, 241, 241, 1);
  --global-neutral-grey-400: rgba(236, 236, 237, 1);
  --global-neutral-grey-500: rgba(230, 230, 230, 1);
  --global-neutral-grey-600: rgba(220, 220, 222, 1);
  --global-neutral-grey-700: rgba(201, 201, 204, 1);
  --global-neutral-grey-900: rgba(127, 125, 131, 1);
  --global-red-100: rgba(254, 235, 235, 1);
  --global-red-200: rgba(255, 227, 227, 1);
  --global-red-700: rgba(245, 53, 53, 1);
  --global-red-800: rgba(225, 33, 33, 1);
  --globalbasewhite: rgba(255, 255, 255, 1);
  --globalblue-1000: rgba(17, 59, 152, 1);
  --globalblue-900: rgba(23, 81, 208, 1);
  --globalneutral-grey200: rgba(245, 245, 245, 1);
  --kokorkoactive-indicator: rgba(243, 248, 255, 1);
  --kokorkoblackblack-100: rgba(176, 176, 176, 1);
  --kokorkoblackblack-200: rgba(138, 138, 138, 1);
  --kokorkoblackblack-300: rgba(84, 84, 84, 1);
  --kokorkoblackblack-400: rgba(34, 34, 34, 1);
  --kokorkoblackblack-50: rgba(230, 230, 230, 1);
  --kokorkoblackblack-500: rgba(0, 0, 0, 1);
  --kokorkoblackblack-600: rgba(0, 0, 0, 1);
  --kokorkoblackblack-700: rgba(0, 0, 0, 1);
  --kokorkoblackblack-800: rgba(0, 0, 0, 1);
  --kokorkoblackblack-900: rgba(34, 34, 34, 1);
  --kokorkoblueblue-100: rgba(188, 201, 227, 1);
  --kokorkoblueblue-200: rgba(156, 175, 214, 1);
  --kokorkoblueblue-300: rgba(110, 139, 195, 1);
  --kokorkoblueblue-400: rgba(82, 117, 183, 1);
  --kokorkoblueblue-50: rgba(233, 238, 246, 1);
  --kokorkoblueblue-600: rgba(35, 75, 150, 1);
  --kokorkoblueblue-700: rgba(28, 58, 117, 1);
  --kokorkoblueblue-800: rgba(21, 45, 91, 1);
  --kokorkoblueblue-900: rgba(16, 34, 69, 1);
  --kokorkoblueprimary-500: rgba(39, 82, 165, 1);
  --kokorkogreygrey-100: rgba(233, 233, 233, 1);
  --kokorkogreygrey-200: rgba(222, 222, 222, 1);
  --kokorkogreygrey-300: rgba(207, 207, 207, 1);
  --kokorkogreygrey-400: rgba(198, 198, 198, 1);
  --kokorkogreygrey-50: rgba(248, 248, 248, 1);
  --kokorkogreygrey-500: rgba(184, 184, 184, 1);
  --kokorkogreygrey-600: rgba(167, 167, 167, 1);
  --kokorkogreygrey-700: rgba(131, 131, 131, 1);
  --kokorkogreygrey-800: rgba(101, 101, 101, 1);
  --kokorkogreygrey-900: rgba(77, 77, 77, 1);
  --kokorkoredred-100: rgba(255, 192, 178, 1);
  --kokorkoredred-200: rgba(255, 162, 140, 1);
  --kokorkoredred-300: rgba(255, 119, 88, 1);
  --kokorkoredred-400: rgba(255, 93, 55, 1);
  --kokorkoredred-50: rgba(255, 235, 230, 1);
  --kokorkoredred-600: rgba(232, 47, 5, 1);
  --kokorkoredred-700: rgba(181, 37, 4, 1);
  --kokorkoredred-800: rgba(140, 29, 3, 1);
  --kokorkoredred-900: rgba(107, 22, 2, 1);
  --kokorkoredsecondary-500: rgba(255, 52, 5, 1);
  --kokorkowhite: rgba(250, 250, 250, 1);
  --medium-12-font-family: "Inter", Helvetica;
  --medium-12-font-size: 12px;
  --medium-12-font-style: normal;
  --medium-12-font-weight: 400;
  --medium-12-letter-spacing: 0px;
  --medium-12-line-height: 18px;
  --medium-14-font-family: "Inter", Helvetica;
  --medium-14-font-size: 14px;
  --medium-14-font-style: normal;
  --medium-14-font-weight: 400;
  --medium-14-letter-spacing: -0.05000000074505806px;
  --medium-14-line-height: 20px;
  --medium-16-font-family: "Inter", Helvetica;
  --medium-16-font-size: 16px;
  --medium-16-font-style: normal;
  --medium-16-font-weight: 400;
  --medium-16-letter-spacing: -0.18000000715255737px;
  --medium-16-line-height: 22px;
  --medium-18-font-family: "Inter", Helvetica;
  --medium-18-font-size: 18px;
  --medium-18-font-style: normal;
  --medium-18-font-weight: 400;
  --medium-18-letter-spacing: -0.25999999046325684px;
  --medium-18-line-height: 24px;
  --primary500-base: rgba(32, 128, 246, 1);
  --regular-14-font-family: "Inter", Helvetica;
  --regular-14-font-size: 14px;
  --regular-14-font-style: normal;
  --regular-14-font-weight: 400;
  --regular-14-letter-spacing: -0.05000000074505806px;
  --regular-14-line-height: 20px;
  --semibold-14-font-family: "Inter", Helvetica;
  --semibold-14-font-size: 14px;
  --semibold-14-font-style: normal;
  --semibold-14-font-weight: 600;
  --semibold-14-letter-spacing: -0.019999999552965164px;
  --semibold-14-line-height: 20px;
  --semibold-16-font-family: "Inter", Helvetica;
  --semibold-16-font-size: 16px;
  --semibold-16-font-style: normal;
  --semibold-16-font-weight: 600;
  --semibold-16-letter-spacing: -0.18000000715255737px;
  --semibold-16-line-height: 22px;
  --shadow-hard-2x-small: 0px 0px 0px 1px rgba(10, 9, 11, 0.05), 0px 2px 7px 0px rgba(10, 9, 11, 0.05),
    0px 2px 5px -2px rgba(10, 9, 11, 0.06);
  --shadow-hard-extra-small: 0px 0px 0px 1px rgba(10, 9, 11, 0.05), 0px 2px 2px -1px rgba(10, 9, 11, 0.04),
    0px 2px 12px -1px rgba(10, 9, 11, 0.1);
  --shadow-hard-small: 0px 0px 0px 1px rgba(10, 9, 11, 0.05), 0px 6px 16px 0px rgba(10, 9, 11, 0.08);
  --shadow-soft-2x-small: 0px 1.5px 4px -1px rgba(10, 9, 11, 0.07);
  --states-focused-accent: 0px 0px 0px 3px rgba(97, 141, 242, 1), 0px 0px 0px 1px rgba(255, 255, 255, 1);
  --states-focused-destructive: 0px 0px 0px 3px rgba(250, 164, 164, 1), 0px 0px 0px 1px rgba(255, 255, 255, 1);
  --states-focused-primary: 0px 0px 0px 3px rgba(220, 220, 222, 1), 0px 0px 0px 1px rgba(255, 255, 255, 1);
}
